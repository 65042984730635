import React, { useState, useEffect } from "react"

import { useWizard } from 'react-use-wizard'
import { useHistory, useLocation } from 'react-router';

import decamelizeKeys from 'decamelize-keys';

import { Client } from "../../../client"

import { SlidingScaleQuestion } from "../../FormQuestions"

function SlidingScaleStep({id, questionJson: {question, minLabel, maxLabel}, templateVariables, surveyId, isTest, cintguid, registerProps, watch}) {
  const value = watch()[id]
  const { nextStep } = useWizard()

  const submit = () => {
    nextStep()
  }

  const formattedQuestion = Object.entries(decamelizeKeys(templateVariables)).reduce(
    (acc, cur) => {
      return acc.replace(new RegExp(`<%= ${cur[0]} %>`, 'g'), cur[1])
    },
    question
  )

  return (
    <div className="content">
      <h1>{formattedQuestion}</h1>
      <SlidingScaleQuestion
        min={0}
        max={100}
        minLabel={minLabel}
        maxLabel={maxLabel}
        registerProps={registerProps}
      />
      <button disabled={value === undefined} className="button" onClick={submit}>Continue</button>
    </div>
  )
}

export default SlidingScaleStep
