import React, { useEffect } from "react"

import { useWizard } from 'react-use-wizard'
import { useHistory, useLocation } from 'react-router';

import decamelizeKeys from 'decamelize-keys';

import { Client } from "../../../client"

import Table from "./MatrixTableStep/Table"
import TextBoxStep from "./TextBoxStep"

function FollowUp({followUp, isTest, skip, registerProps, value}) {
  const { nextStep } = useWizard()

  useEffect(() => {
      console.log('hi')
      console.log(skip)
    if (skip) {
      nextStep()
    }
  }, [skip])

  return (
    <div className={""}>
      <TextBoxStep
        questionJson={{question: followUp.question}}
        isTest={isTest}
        registerProps={registerProps}
        value={value}
      />
    </div>
  )
}

function MatrixTableStep({id, questionJson: {questions, question}, isTest, register, watch, setValue}) {
  const values = watch()[id]
  const followUpQuestions = questions
    .flatMap((q, i) => {
      if (q.followUps.length === 0) {
        return []
      }
      return q.followUps.flatMap(followUp => {
        const value = values?.at(i)
        const testVal = value?.value === undefined ? 5 : value.value
        console.log(value)
        console.log(testVal)
        console.log(followUp.threshold)
        return [
          <FollowUp
            followUp={followUp}
            isTest={isTest}
            skip={followUp.threshold > testVal}
            registerProps={register(`${id}[${i}].followUp`)}
            value={values?.at(i)?.followUp}
          />
        ]
      })
    })

  return [
    <Table
      id={id}
      isTest={isTest}
      question={question}
      questions={questions}
      setValue={setValue}
      watch={watch}
    />,
    ...followUpQuestions
  ]
}

export default MatrixTableStep
