import React, {useState} from "react"

import { useWizard } from "react-use-wizard"

import { Client } from "../../../client"
import { DropdownQuestion } from "../../FormQuestions"

function AgeScreenout({maxAge, minAge, cintguid, isTest}) {
  const [age, setAge] = useState()
  const { nextStep } = useWizard()

  const options = [...Array(84).keys()].map(i => {
    return {
      id: `${i + 16}`,
      text: `${i + 16}`
    }
  })

  const submit = () => {
    const parsedAge = parseInt(age)
    if ((parsedAge >= minAge) && (parsedAge <= maxAge)) { 
      nextStep()
    } else {
      Client.setSurveyScreenout({
        cintguid,
        reason: `Responded age is ${parsedAge} and the range is ${minAge} - ${maxAge}`
      }).then(resp => {
        window.location.replace(resp.data.redirectUrl)
      })
    }
  }

  return (
    <div>
      <DropdownQuestion
        label="How old are you?"
        value={age}
        setValue={setAge}
        options={options}
      />
      <button disabled={age === undefined && !isTest} className="button" onClick={submit}>Continue</button>
    </div>
  )
}

export default AgeScreenout
