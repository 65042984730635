import React, {useState, useEffect} from 'react';
import {
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";

import Navbar from './components/Navbar';
import {Notifications} from "./components/notifications/Notifications";

import {useAuth} from "./hooks/useAuth";

import SignIn from './pages/SignIn';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import Survey from './pages/Survey';
import Pricing from './pages/Pricing';
import OurTeam from './pages/OurTeam';

function App() {
  const auth = useAuth();

  useEffect(() => {
    //auth.getSelf()
  }, [])

  return (
    <>
      <Navbar />
      <Notifications />
      <Switch>
        <Route path="/survey/:surveyId">
          <Survey />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/sign_in">
          <SignIn />
        </Route>
        <Route path="/login">
          <SignIn />
        </Route>
        <Route exact path="/about_us">
          <AboutUs />
        </Route>
        <Route exact path="/services">
          <Services />
        </Route>
        <Route exact path="/pricing">
          <Pricing />
        </Route>
        <Route exact path="/our_team">
          <OurTeam />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </>
  )       
}

export default App;
