import React, {useContext, createContext, useState} from "react";
import { useDispatch } from 'react-redux';

import {Client} from "../client";

// Taken mostly from https://usehooks.com/useAuth/

const authContext = createContext();

export const LOGOUT = "AUTH_LOGOUT";

export const useAuth = () => {
  return useContext(authContext)
}

export function AuthProvider({children}) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useAuthProvider() {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const signIn = (email, password) => {
    setLoading(true)
    return Client.getSession(email, password).then(s => {
      return s
    }).finally(() => {
      setLoading(false)
    })
  }
  const signOut = () => {
    setLoading(true)
    Client.signOut().then(resp => {
      dispatch({type: LOGOUT})
      setUser()
    }).finally(() => {
      setLoading(false)
    })
  }
  const sendPasswordResetEmail = (email) => {
  }

  const getSelf = () => {
    setLoading(true)
    return Client.getSelf().then(resp => {
      if (resp.data.success) {
        setUser({
          loaded: true,
          ...resp.data.user
        })
      } else {
        setUser({
          loaded: false
        })
      }
      return resp
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    user,
    signIn,
    signOut,
    sendPasswordResetEmail,
    getSelf,
    loading,
  }
}
