import React, {useEffect, useState} from "react";

import { Wizard, useWizard } from 'react-use-wizard';
import { useHistory, useLocation } from 'react-router';

import { useForm } from "react-hook-form"

import VideoRecorder from 'react-video-recorder'

import Axios from 'axios';

import { S3Client, ListBucketsCommand } from "@aws-sdk/client-s3";

import { Client } from "../../client"

import VideoWatch from "./steps/VideoWatch"
import EndStep from "./steps/EndStep"
import MultipleChoiceStep from "./steps/MultipleChoiceStep"
import TextBoxStep from "./steps/TextBoxStep"
import AgeScreenout from "./steps/AgeScreenout"
import SlidingScaleStep from "./steps/SlidingScaleStep"
import MatrixTableStep from "./steps/MatrixTableStep"
import WebcamConsent from "./steps/WebcamConsent"

function Header({onConsent, consented, recording, setRecording, surveyId, setUploaded}) {
  const { activeStep, stepCount } = useWizard();
  const [url, setUrl] = useState()
  const [stopped, setStopped] = useState(false)

  useEffect(() => {
    Client.getPresignedUrlForWebcam({surveyId}).then(resp => {
      setUrl(resp.data.url)
    })
  }, [])

  const onStartRecording = () => {
    setRecording(true)
    Client.logVideoTimestamp({
      timestamp: "start_timestamp",
      value: Date.now(),
      surveyId: surveyId
    })
  }

  const onStopRecording = () => {
    setRecording(false)
    setStopped(true)
    Client.logVideoTimestamp({
      timestamp: "end_timestamp",
      value: Date.now(),
      surveyId: surveyId
    })
  }
  console.log(consented)
  console.log(stopped)
  console.log(recording)
  console.log(activeStep)

  return (
    <>
      {consented && <VideoRecorder
          onRecordingComplete={(videoBlob) => {
              Axios.put(
                url,
                videoBlob,
                {
                  headers: {
                    'Content-Type': 'video/webm'
                  }
              }).then( resp => {
                setUploaded(true)
              })
            }}
          isOnInitially={true}
          timeLimit={1000*60*60*24}
          countdownTime={0}
          wrapperClassName={!onConsent || stopped || (activeStep === 0 && recording) ? "is-hidden" : ""}
          onStartRecording={onStartRecording}
          onStopRecording={onStopRecording}
        />}
      <progress className="progress" value={activeStep + 1} max={stepCount} />
    </>
  )
}

function SurveyWizard({survey, isTest, cintguid}) {
  const [consented, setConsented] = useState(false)
  const [onConsent, setOnConsent] = useState(false)
  const [recording, setRecording] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const { register, setValue, watch } = useForm()

  const values = (watch())

  const surveyQuestions = survey.surveyQuestions.filter(q => !Object.keys(survey.surveyResponses).includes(q.id.toString())).flatMap((q, i) => {
    if (q.questionType === 'multiple_choice') {
      return [(
        <MultipleChoiceStep
          surveyId={survey.surveyId}
          cintguid={cintguid}
          isTest={isTest}
          {...q}
          registerProps={register(q.id.toString())}
          watch={watch}
        />
      )]
    } else if (q.questionType === 'text_block') {
      return [(
        <TextBoxStep
          surveyId={survey.surveyId}
          cintguid={cintguid}
          isTest={isTest}
          {...q}
          registerProps={register(q.id.toString())}
          value={values[q.id]}
        />
      )]
    } else if (q.questionType === 'sliding_scale') {
      return [(
        <SlidingScaleStep
          surveyId={survey.surveyId}
          cintguid={cintguid}
          isTest={isTest}
          {...q}
          registerProps={register(q.id.toString())}
          watch={watch}
        />
      )]
    } else if (q.questionType === 'matrix_table') {
      return MatrixTableStep({isTest, register, watch, setValue, ...q})
    } else {
      return []
    }
  })

  return (
    <Wizard
      header={<Header consented={consented} onConsent={onConsent} recording={recording} setRecording={setRecording} surveyId={survey.surveyId} uploaded={uploaded} setUploaded={setUploaded}/>}
    >
      {survey.respondentVideoAnalysis && <WebcamConsent consented={consented} setConsented={setConsented} setOnConsent={setOnConsent} recording={recording}/>}
      <AgeScreenout {...survey.ageRange} cintguid={cintguid} isTest={isTest} />
      <VideoWatch videoUrl={survey.videoUrl} isTest={isTest} idx={1} surveyId={survey.surveyId}/>
      <VideoWatch videoUrl={survey.videoUrl} isTest={isTest} idx={2} surveyId={survey.surveyId}/>
      {surveyQuestions}
      <EndStep cintguid={cintguid} responses={values} setOnConsent={setOnConsent} recording={recording} uploaded={uploaded}/>
    </Wizard>
  )
}

export default SurveyWizard
