import React, {useEffect, useState} from "react"

import { Client } from "../../../client"

function EndStep({cintguid, responses, setOnConsent, recording, uploaded}) {

  useEffect(() => {
    setOnConsent(true)
  }, [])

  const cont = () => {
    Client.setSurveyComplete({cintguid, responses}).then(resp => {
      window.location.replace(resp.data.redirectUrl)
    })
  }
  return (
    <div>
      Thank you for completing the survey<br/>
      Please stop the recording before continuing.

      <button className={"button" + (!uploaded ? " is-loading": "")} disabled={recording || !uploaded} onClick={cont}>Continue</button>
    </div>
  )
}

export default EndStep
