import React, {useEffect} from 'react';
import { connect } from "react-redux";

import {dismissNotification} from '../../store/notifications/actions';

function NotificationComponent({message, id, dismissNotification}){
    useEffect(() => {
        setTimeout(() => dismissNotification(id), 5000)
    },[id, dismissNotification])

    return (
        <div className="notification is-warning">
            <button onClick={() => dismissNotification(id)} className="delete"></button>
            {message}
        </div>
    )
}
const Notification = connect(null, {dismissNotification})(NotificationComponent)

function NotificationsComponent({notifications}){
    const notificationObjects = notifications.map((notification, index) => {
        return (
            <Notification
                key={index}
                message={notification.message}
                id={notification.id}
            />
        )
    })
    const style = {
        position: "fixed",
        right: "1rem",
        zIndex: "100",
    }
    return (
        <div style={style}>
            {notificationObjects}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications.notifications
    }
}

const Notifications = connect(mapStateToProps, {})(NotificationsComponent)

export {Notifications}

