import React from "react";

import FooterLinks from "../components/FooterLinks";

function OurTeam() {
  return (
      <>
      <section className="hero is-medium">
        <div className="hero-body">
          <h1 className="title">
    adunio was formed by two people with day jobs in advertising and technology + decades of experience between them 
          </h1>
          <h1 className="title">
    We are fortunate to have a tribe of friends, advisors and early adopters to guide us
          </h1>
        </div>
      </section>
      <footer className="footer" style={{width: '100%'}}>
        <FooterLinks />
      </footer>
      </>
  )
}

export default OurTeam
