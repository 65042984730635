import Axios from 'axios';
import applyConverters from 'axios-case-converter';

const csrfElement = document.querySelector('meta[name="csrf-token"]')

const baseConfig = {
  baseURL: '/api',
  timeout: 60 * 1000,
  headers: {
    'X-CSRF-Token': csrfElement.content,
  }
}

export const baseClient = applyConverters(Axios.create(baseConfig))

export class Client {
  static getSurvey({surveyId}) {
    return baseClient.get(`/surveys/${surveyId}`)
  }

  static signUp(payload) {
    return baseClient.post('/auth/sign_up', payload)
  }

  static getSession(email, password) {
    return baseClient.post('/auth/session', {email, password})
  }

  static signOut() {
    return baseClient.delete('/auth/session')
  }

  static getSelf() {
    return baseClient.get('/user')
  }

  static setSurveyComplete({cintguid, responses}) {
    return baseClient.post('/survey_complete', {cintguid, responses})
  }

  static setSurveyScreenout({cintguid, reason}) {
    return baseClient.post('/survey_screenout', {cintguid, reason})
  }

  static getPresignedUrlForWebcam({surveyId}) {
    return baseClient.get('/presigned_url_for_webcam', {params: {surveyId}})
  }

  static logVideoTimestamp({timestamp, value, surveyId}) {
    return baseClient.put('/video_timestamp', {surveyId, timestamp, value})
  }
}
