export const OPEN_MENU = "MISC_OPEN_MENU";
export function openMenu(){
    return {
        type: OPEN_MENU,
    }
}
export const CLOSE_MENU = "MISC_CLOSE_MENU";
export function closeMenu(){
    return {
        type: CLOSE_MENU,
    }
}
