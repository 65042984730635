import React from "react"

import { useWizard } from 'react-use-wizard'

function Table({id, question, questions, isTest, watch, setValue}) {
  const values = watch(`${id}`)

  const updateValue = (index, value) => {
    const oldValue = values?.at(index) === undefined ? {} : values.at(index)
    oldValue['value'] = value
    
    setValue(`${id}[${index}]`, oldValue)
  }

  const valid = values?.map(a => a?.value).filter(a => undefined !== a).length === questions.length
  const { nextStep } = useWizard()

  const questionRows = questions.map((q, i) => {
    const value = values?.at(i)?.value
    return (
      <tr>
        <td>{q.question}</td>
        <td><button className={"button" + (value=== 1 ? " is-primary" : "")} onClick={() => updateValue(i, 1)}>{1}</button></td>
        <td><button className={"button" + (value=== 2 ? " is-primary" : "")} onClick={() => updateValue(i, 2)}>{2}</button></td>
        <td><button className={"button" + (value=== 3 ? " is-primary" : "")} onClick={() => updateValue(i, 3)}>{3}</button></td>
        <td><button className={"button" + (value=== 4 ? " is-primary" : "")} onClick={() => updateValue(i, 4)}>{4}</button></td>
        <td><button className={"button" + (value=== 5 ? " is-primary" : "")} onClick={() => updateValue(i, 5)}>{5}</button></td>
      </tr>
    )
  })

  return (
    <div className="content">
      <h1>{question}</h1>
      <table className="table">
        <thead>
          <tr>
            <td></td>
            <td>Agree strongly</td>
            <td>Agree somewhat</td>
            <td>Neither agree nor disagree</td>
            <td>Disagree somewhat</td>
            <td>Disagree strongly</td>
          </tr>
        </thead>
        <tbody>
          {questionRows}
        </tbody>
      </table>
      <button disabled={!valid && !isTest} onClick={nextStep} className="button is-primary">Next</button>
    </div>
  )
}

export default Table
