import React from 'react'
import { Provider } from 'react-redux';
import App from '../App'
import { createRoot } from 'react-dom/client';
import { ConnectedRouter } from 'connected-react-router';

import {AuthProvider} from "../hooks/useAuth";
import configureStore, {history} from "../store";

const store = configureStore();

const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <AuthProvider>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </AuthProvider>
  </Provider>
);
