import {
    OPEN_MENU,
    CLOSE_MENU,
} from "./actions";

const initialState = {
    menuOpen: false
}

export default function miscReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_MENU:
            return {
                ...state,
                menuOpen: true,
            }
        case CLOSE_MENU:
            return {
                ...state,
                menuOpen: false,
            }
        case "@@router/LOCATION_CHANGE":
            return {
                ...state,
                menuOpen: false,
            }
        default:
            return state
    }
}
