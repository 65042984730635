import React, { useState, useEffect } from "react"

import { useWizard } from 'react-use-wizard'
import { useHistory, useLocation } from 'react-router';

import decamelizeKeys from 'decamelize-keys';

import { Client } from "../../../client"

import { RadioQuestion } from "../../FormQuestions"

function MultipleChoiceStep({id, templateVariables, questionJson: {question, answers}, isTest, registerProps, watch}) {
  const value = watch()[id]

  const formattedQuestion = Object.entries(decamelizeKeys(templateVariables)).reduce(
    (acc, cur) => {
      return acc.replace(new RegExp(`<%= ${cur[0]} %>`, 'g'), cur[1])
    },
    question
  )

  const formattedAnswers = answers.map(answer => {
    return Object.entries(decamelizeKeys(templateVariables)).reduce(
      (acc, cur) => {
        const key = cur[0]
        const val = cur[1]
        return acc.replace(new RegExp(`<%= ${key} %>`, 'g'), val)
      },
      answer
    )
  })
  const { nextStep } = useWizard()

  const submit = () => {
    nextStep()
  }

  console.log(value)

  return (
    <div className="content">
      <h1>{formattedQuestion}</h1>
      <RadioQuestion
        registerProps={registerProps}
        options={formattedAnswers}
      />
      <button disabled={(value === undefined || value === null) && !isTest} className="button" onClick={submit}>Continue</button>
    </div>
  )
}

export default MultipleChoiceStep
