import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import {
    combineReducers,
    createStore,
    applyMiddleware
} from 'redux';

import logger from 'redux-logger';

import {default as MiscReducer} from "./store/misc/reducers"
import {default as NotificationsReducer} from "./store/notifications/reducers"

// Reducers
const createReducer = history => combineReducers({
    router: connectRouter(history),
    misc: MiscReducer,
    notifications: NotificationsReducer,
})

// Create browser history
export const history = createBrowserHistory()

export default function configureStore() {
    const store = createStore(
        createReducer(history),
        applyMiddleware(
            routerMiddleware(history),
            logger
        )
    );

    return store;
}
