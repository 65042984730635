import {
    CREATE_NOTIFICATION,
    DISMISS_NOTIFICATION
} from "./actions";

import {LOGOUT} from "../../hooks/useAuth";

const initialState = {
    notifications: []
}

export default function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
          return initialState
        case CREATE_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, {
                    message: action.message,
                    id: action.id
                }]
            }
        case DISMISS_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id !== action.id)
            }
        default:
            return state
    }
}

