import React, {useState, useEffect} from "react";

import ReactHtmlParser from 'react-html-parser';

function SlidingScaleQuestion({
  label,
  value,
  setValue,
  min,
  max,
  minLabel,
  maxLabel,
  registerProps
}) {
  return (
    <>
      <label className="label">{label}</label>
      <div>
        <input type="range" style={{width: '100%'}} min={min} max={max} value={value} onChange={(e) => setValue(e.target.value)} className="slider" id="myRange" {...registerProps}/>
        <div style={{display: 'flex', justifyContent: 'space-between' }}>
          <p>{minLabel}</p>
          <p>{maxLabel}</p>
        </div>
      </div>
    </>
  )
}

function TextAreaQuestion({
  value,
  setValue,
  label,
  placeholder,
  registerProps,
  ...props
}) {
  const handleFieldChange = (changeEvent) => {
    setValue(changeEvent.target.value)
  }

  return (
    <>
      <label className="label">{label}</label>
      <div className={"field"} key={props.key}>
        <div className="control">
          <textarea 
            className="textarea"
            placeholder={placeholder}
            value={value}
            onChange={handleFieldChange}
            {...registerProps}
          ></textarea>
        </div>
      </div>
    </>
  )
}

function TextQuestion({
        value, setValue,
        label,
        type,
        placeholder,
        hidden,
        disabled,
        validationError,
        onKeyDown,
        registerProps,
        addOns,
        ...props
    }) {
    const [visible, setVisible] = useState(false)

    const handleFieldChange = (changeEvent) => {
        setValue(changeEvent.target.value)
    }

    const is_hidden = hidden ? "is-hidden" : "";
    const has_addons = addOns === undefined ? "" : " has-addons";
    return (
        <>
            <label className="label">{label}</label>
            <div className={"field " + is_hidden + has_addons} key={props.key}>
                <div className="control has-icons-right">
                    <input
                        className={"input" + (validationError === undefined || validationError === "" ? "" : " is-danger")}
                        disabled={disabled}
                        type={type === "password" && ! visible ? "password" : (type === "password" ? "" : type)}
                        value={value}
                        onChange={handleFieldChange}
                        placeholder={placeholder}
                        onKeyDown={onKeyDown}
                        {...registerProps}
                    />
                    <span className={"icon is-small is-right" + (type === "password" ? "" : " is-hidden")}
                            style={{"pointer-events": "initial"}}
                            onClick={() => setVisible(!visible)}
                    >
                        <i
                            className={visible ? "fas fa-eye" : "fas fa-eye-slash"}
                        />
                    </span>
                </div>
                {addOns}
            </div>
            <p className="help is-danger">{validationError}</p>
        </>
    )
}

function RadioQuestion({
  value, setValue,
  registerProps,
  validationError,
  options=[]
}) {
  return options.map((o, i) => {
    return (
      <>
        <div className="radio">
          <label>
            <input
              {...registerProps}
              type="radio"
              value={i}
            />
          &nbsp;{ReactHtmlParser(o)}
          </label>
        </div>
        <br />
      </>
    )
  })
}

function DropdownQuestion({
        value, setValue,
        label,
        options,
        hidden,
        disabled,
        noEmptyOption,
        registerProps,
        validationError,
        key,
        ...props
    }) {
    const handleFieldChange = (changeEvent) => {
        if (setValue === undefined) {
        } else {
            setValue(changeEvent.target.value)
        }
    }
    const is_hidden = hidden ? "is-hidden" : ""

    const dropdownOptions = options.map(option => (
            <option value={option.id} key={option.id} >{option.text}</option>
    ))

    return (
        <div className={"field " + is_hidden} key={key}>
            <label className="label">{label}</label>
            <div className="control">
                <div className={"select" + (validationError === undefined ? "" : " is-danger")}>
                    <select disabled={disabled} onChange={handleFieldChange} value={value} {...registerProps}>
                        <option className={noEmptyOption ? "is-hidden" : ""} key=""></option>
                        { dropdownOptions}
                    </select>
                </div>
                <p className="help is-danger">{validationError}</p>
            </div>
        </div>
    )
}


function ConfirmButton ({onClick = () => {}, text, extraClasses, disabled}) {
    const [confirming, setConfirming] = useState(false);

    const clicker = () => {
        if (confirming) {
            onClick()
            setConfirming(false)
        } else {
            setConfirming(true)
        }
    }
    return (
        <button disabled={disabled} className={"button" + (extraClasses === undefined ? "" : extraClasses)} onClick={() => clicker()} >{text + (confirming ? " (Click to Confirm)" : "")}</button>
    )
}

export {
  DropdownQuestion,
  TextQuestion,
  ConfirmButton,
  RadioQuestion,
  TextAreaQuestion,
  SlidingScaleQuestion
};

