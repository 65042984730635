import React, { useState } from "react";

import FooterLinks from "../components/FooterLinks";

function AboutUs() {
  const [open, setOpen] = useState(false)

  return (
      <>
      <section className="hero is-large">
        <div className="hero-body">
          <h1 className="title">
            Please contact us for pricing
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <button className="button is-primary" onClick={() => setOpen(true)}>Contact Us</button>
          </h1>
        </div>
      </section>
      <div className={"modal" + (open ? " is-active" : "")}>
        <div className="modal-background" onClick={() => setOpen(false)}></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div>
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScZGCoW5Jla6Lr0qsOEr6D-GLQLtaoNy07h9R2Y-sB2jdkWrQ/viewform?embedded=true" width="640" height="824" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
            <button className="button" aria-label="close" onClick={() => setOpen(false)}>Close</button>
          </section>
        </div>
      </div>
      <footer className="footer" style={{width: '100%'}}>
        <FooterLinks />
      </footer>
      </>
  )
}

export default AboutUs
