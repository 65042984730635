import React, {useEffect} from "react";
import {connect} from "react-redux";
import {push} from "connected-react-router";

import {useAuth} from "../../hooks/useAuth";

function CustomerDashboard({...props}) {
  const auth = useAuth()

  return (
    <>
          <section className="section">
            <div className="columns">
              <div className="column is-half">
                hi I have something interesting coming.<br/>
                please be patient with me.
              </div>
            </div>
          </section>
    </>
  )
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps, {push})(CustomerDashboard)
