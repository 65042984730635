import React from "react"

function FooterLinks() {
  return (
    <>
      <p>
        <a href="/about_us" style={{color: "black"}}>About Us</a>
      </p>
      <p>
        <a href="/services" style={{color: "black"}}>Services</a>
      </p>
      <p>
        <a href="/pricing" style={{color: "black"}}>Pricing</a>
      </p>
      <p>
        <a href="/our_team" style={{color: "black"}}>Our Team</a>
      </p>
    </>
  )
}

export default FooterLinks
