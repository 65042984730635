import React, { useRef, useState, useEffect } from "react"

import { useWizard } from 'react-use-wizard';

import { Client } from "../../../client"

function VideoWatch({videoUrl, isTest, idx, surveyId}) {
  const { nextStep } = useWizard();

  const [started, setStarted] = useState(false)
  const [ended, setEnded] = useState(false)
  const [timer, setTimer] = useState(false)

  const videoRef = useRef()
  useEffect(() => {
    if (videoRef !== undefined) {
      videoRef.current.addEventListener('ended', () => {
        setEnded(true)
      })
      videoRef.current.addEventListener('play', () => {
        Client.logVideoTimestamp({
          timestamp: `view_${idx}_timestamp`,
          value: Date.now(),
          surveyId: surveyId
        })
        setStarted(true)
        setTimeout(() => {
          setTimer(true)
        }, (videoRef.current.duration * 1000))
      })
    }
  }, [videoRef])

  return (
    <div>
      <video controls={!started} controlsList="nodownload" preload="auto" ref={videoRef}>
        <source src={videoUrl} type="video/mp4"/>
      </video>
      <button className="button" disabled={(!ended || !timer) && !isTest} onClick={nextStep}>Continue</button>
    </div>
  )
}

export default VideoWatch
