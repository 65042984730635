import React, { useRef, useState, useEffect } from "react"

import { useWizard } from 'react-use-wizard';

function WebcamConsent({consented, setConsented, setOnConsent, recording}) {
  const { nextStep } = useWizard();

  useEffect(() => {
    setOnConsent(true)
  }, [setOnConsent])

  const next = () => {
    nextStep()
    setOnConsent(false)
  }

  return (
    <div>
      By clicking the below "I Consent" button you are allowing us to turn on your webcam and record you while you take the survey.
      <div className="buttons">
        <button className="button is-warning" disabled={consented} onClick={() => setConsented(true)}>I Consent</button>
        <button className="button" disabled={!consented || !recording} onClick={next}>Continue</button>
      </div>
    </div>
  )
}

export default WebcamConsent
