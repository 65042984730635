import React, {useEffect} from "react";
import {connect} from "react-redux";
import {push} from "connected-react-router";

function AdminDashboard({...props}) {
    return (
      <>
      </>
    )
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps, {push})(AdminDashboard)
