import React, { useState, useEffect } from "react"

import { useWizard } from 'react-use-wizard'
import { useHistory, useLocation } from 'react-router';

import { Client } from "../../../client"

import { TextAreaQuestion } from "../../FormQuestions"

function TextBoxStep({id, value, questionJson: {question}, surveyId, isTest, cintguid, registerProps}) {
  const { nextStep } = useWizard()

  const submit = () => {
    nextStep()
  }

  return (
    <div>
      <TextAreaQuestion
        registerProps={registerProps}
        label={question}
      />
      <button disabled={(value === undefined || value === "") && !isTest} className="button" onClick={submit}>Continue</button>
    </div>
  )
}

export default TextBoxStep
