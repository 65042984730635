import React from "react";

import FooterLinks from "../components/FooterLinks";

function AboutUs() {
  return (
      <>
      <section className="section">
        <div className="hero-body " style={{flexDirection: "column", alignItems: "stretch"}}>
          <h1 className="title" style={{padding: '3em'}}>
            adunio is a self serve creative testing platform that makes it easy to measure the effectiveness of video ads
          </h1>
        </div>
      </section>
      <footer className="footer" style={{width: '100%'}}>
        <FooterLinks />
      </footer>
      </>
  )
}

export default AboutUs
