import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {Redirect} from "react-router";

import AdminDashboard from "../components/dashboards/AdminDashboard";
import CustomerDashboard from "../components/dashboards/CustomerDashboard";
import FooterLinks from "../components/FooterLinks";

import {Client} from "../client";

import {useAuth} from "../hooks/useAuth";

function HomeComponent({push, ...props}) {
  const [open, setOpen] = useState(false)
  const auth = useAuth();

  const role = auth.user?.role;

  if (role === "admin") {
    return (<AdminDashboard />)
  } else if (role === "customer") {
    return (<CustomerDashboard />)
  } else {
    return (
      <>
      <section className="hero is-medium">
        <div className="hero-body">
          <h1 className="title">
            Unlock the power of your video ads through data - creative testing made easy and speedy!
            <br />
            <br />
            <br />
            <br />
            <button className="button is-primary" onClick={() => setOpen(true)}>Contact Us</button>
          </h1>
        </div>
      </section>
      <div className={"modal" + (open ? " is-active" : "")}>
        <div className="modal-background" onClick={() => setOpen(false)}></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div>
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScZGCoW5Jla6Lr0qsOEr6D-GLQLtaoNy07h9R2Y-sB2jdkWrQ/viewform?embedded=true" width="640" height="824" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
            <button className="button" aria-label="close" onClick={() => setOpen(false)}>Close</button>
          </section>
        </div>
      </div>
      <footer className="footer" style={{width: '100%'}}>
        <FooterLinks />
      </footer>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps, {})(HomeComponent)
