import React from "react";

import FooterLinks from "../components/FooterLinks";

function Services() {
  return (
      <>
      <section className="section">
        <div className="hero-body " style={{flexDirection: "column", alignItems: "stretch"}}>
          <div className="content">
            <div className="content">
          <h1 className="title" >
            Adunio provides a one-stop platform to test all types of video ads:
          </h1>
          <div style={{padding: '3em'}}>
            <li>
              <li>TV</li>
              <li>Cinema</li>
              <li>Digital OOH</li>
              <li>Social feeds, stories and reels</li>
              <li>YouTube & Programmatic Video</li>
              <li>Connected TV</li>
            </li>
            </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer" style={{width: '100%'}}>
        <FooterLinks />
      </footer>
      </>
  )
}

export default Services
