import {v4 as uuid} from 'uuid';

export const CREATE_NOTIFICATION = "NOTIFICATIONS_CREATE_NOTIFICATION";
export function createNotification(message) {
    return {
        type: CREATE_NOTIFICATION,
        message,
        id: uuid()
    }
}

export const DISMISS_NOTIFICATION = "NOTIFICATIONS_DISMISS_NOTIFICATION";
export function dismissNotification(id) {
    return {
        type: DISMISS_NOTIFICATION,
        id
    }
}

