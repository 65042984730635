import React from "react";
import { useParams } from "react-router-dom";

import { usePromise } from "../hooks/usePromise"

import { Client } from "../client";

import SurveyWizard from "../components/survey/SurveyWizard"

function Survey() {
  const { surveyId } = useParams()
  const {loading, result} = usePromise(Client.getSurvey, {surveyId})

  const urlParams = new URLSearchParams(window.location.search);

  const isTest = urlParams.get('test') === 'true' ? true : false
  const cintguid = urlParams.get('CintGUID') === null ? undefined : urlParams.get('CintGUID')

  return (
    <div className="section">
      {!loading && result?.survey && <SurveyWizard
          isTest={isTest}
          cintguid={cintguid}
          survey={result.survey}
        />}
    </div>
  )
}

export default Survey
