import React from 'react';
import {
    Link
} from 'react-router-dom';

import { connect } from "react-redux";

import {useAuth} from "../hooks/useAuth";

import {
    openMenu,
    closeMenu
} from "../store/misc/actions";

function NavbarStart({loggedIn}) {
    const auth = useAuth();

    if (auth.user) {
      if (auth.user.role === "admin") {
        return (
            <div className="navbar-start">
                <Link className="navbar-item" to="/my_account">
                  My Account
                </Link>
                <Link className="navbar-item" to="/orders">
                  Orders
                </Link>
                <Link className="navbar-item" to="/products">
                  Products
                </Link>
            </div>
        )
      } else {
        return (<div />)
      }
    } else {
      return (<div />)
    }
}

function NavbarEnd({}) {
  const auth = useAuth();

  if (auth.user) {
    return (
        <div className="navbar-end">
            <div className="navbar-item">
                <div onClick={() => auth.signOut()} className="button is-primary">
                    Logout
                </div>
            </div>
        </div>
    )
  } else {
      return (
          <div className="navbar-end">
              {/*
              <Link className="navbar-item" to="/sign_up">
                  <div className="button is-primary">
                      Sign Up
                  </div>
              </Link>
              <Link className="navbar-item" to="/login">
                  <div className="button">
                      Login
                  </div>
              </Link>
              */}
          </div>
      )
  }
}

function NavbarComponent({name, menuOpen, openMenu, closeMenu, ...props}) {
    const toggleMenu = () => {
        if (!menuOpen) {
            openMenu()
        } else {
            closeMenu()
        }
    }

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link className="navbar-item is-fullheight" to="/">
                    <img
                        className="is-fullheight"
                        src="/images/adunio_logo.jpg"
                        alt="adunio"
                    />
                </Link>
                <div
                    className={"navbar-burger burger " + menuOpen}
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbarBasic"
                    onClick={toggleMenu}
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </div>
            </div>
            <div id="navbarBasic" className={"navbar-menu " + (menuOpen ? "is-active" : "") }>
                <NavbarStart />
                <NavbarEnd />
            </div>
        </nav>
    )
}

const mapStateToProps = state => {
    return {
      menuOpen: state.misc.menuOpen
    }
}

const Navbar = connect(mapStateToProps, {openMenu, closeMenu})(NavbarComponent)

export default Navbar
