import React, {useState} from "react";
import {connect} from "react-redux";
import {push} from 'connected-react-router';

import {createNotification} from "../store/notifications/actions";

import { useAuth } from "../hooks/useAuth";

import {
    TextQuestion
} from "../components/FormQuestions";

function SignIn({push, createNotification, ...props}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const auth = useAuth();

    const signIn = (email, password) => {
      auth.signIn(email, password).then(resp => {
        if (resp.data.success) {
          auth.getSelf().then(resp => {
            if (resp.data.success) {
              push('/home')
            }
          })
        } else {
          createNotification("Unauthorized")
        }
      }).catch(err => {
        createNotification("Unauthorized")
      })

    }

    const buttonDisabled = 
        email === "" ||
        password === ""

    const handleKeyDown = e => {
        if (e.key === 'Enter' && !buttonDisabled) {
            signIn(email,password)
        }
    }

    return (
        <section>
            <div className="container is-fluid">
                <div className="columns">
                    <div className="column is-half is-offset-one-quarter content box">
                        <h1>Login</h1>
                        <TextQuestion
                            value={email}
                            setValue={setEmail}
                            label="Email"
                            placeholder="bob@adun.io"
                            onKeyDown={handleKeyDown}
                        />
                        <TextQuestion
                            value={password}
                            setValue={setPassword}
                            label="Password"
                            type="password"
                            onKeyDown={handleKeyDown}
                        />
                        <div className="buttons">
                            <button className="button is-primary" onClick={() => signIn(email, password)} disabled={buttonDisabled} >Login</button>
                            <button className="button" onClick={() => push("/forgot_password")}>Forgot Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default connect(null, {createNotification, push})(SignIn);
